import React, { Component } from 'react'
import Layout from '../components/layout/layout'

import ServicesDetail from '../components/Services/ServicesDetail'

export default class TechnologyAdvisory extends Component {
  render() {
    const serviceImage = require('../assets/images/rawpixel-557123-unsplash.jpg')
    const sajja = require('../assets/images/sajja.jpg')
    const louis = require('../assets/images/loui.jpg')

    return (
      <Layout location={this.props.location}>
        <ServicesDetail
          service="Technology Advisory"
          serviceCaption="INNOVATION IS NO LONGER AN UPPERHAND, ITS A REQUIREMENT"
          serviceDocumentLink="https://drive.google.com/file/d/14ktdRLmYCbH8MVA9GaavZbV3i0SI6tk7/view?usp=sharing"
          serviceHighlight={[
            'Product development',
            'Tech & Digital strategy',
            'Startup consulting',
          ]}
          serviceTopDescription="The need to innovate has never been greater. We help businesses in creating appropriate innovation partnerships, quickly develop innovation solutions and design an innovation blueprint to help you grow. Companies and Entrepreneurs collaborate with our team of product designers, engineers and strategist to design and develop your next ground breaking application or solution from ideation to execution."
          serviceBottomDescription="Many businesses operate across Africa but not many can claim sustainable and optimal success. To take advantage of the African growth potential, your strategy must be in line with how your targeted African stakeholders maximize their welfare."
          serviceImage={serviceImage}
          serviceSpecialists={[
            {
              name: 'Louis Kinunda',
              title: 'Senior consultant, Business Intelligence',
              linkedin: 'https://www.linkedin.com/in/louis-kinunda-10aaa533/',
              twitter: 'https://twitter.com/Louis_K22',
              image: louis,
            },
            {
              name: 'Steven Sajja',
              title: 'Technology partner, Co-founder',
              linkedin: 'https://www.linkedin.com/in/stevensajja/',
              twitter: 'https://twitter.com/sajeezy',
              image: sajja,
            },
          ]}
        />
      </Layout>
    )
  }
}
